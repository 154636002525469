$main_color_primary: #03A8F9;
$main_color_secondary: #62DF75;
$main_gradient_primary: linear-gradient(67deg, #03A8F9 0%, #62DF75 100%), #FFF;
$background_gradient_primary: linear-gradient(145deg, #E7F3E9 0%, #F6F6F6 100%), #FFF;
$background_gradient_secondary: linear-gradient(146deg, #011128 0%, #013565 100%), #FFF;
$background_dark_primary: rgba(0, 0, 0, 0.40);
$background_light_primary: rgba(113, 128, 150, 0.19);
$text_color: #002B43;
$text_placeholder_color: #718096;
$redColor: #FB3836;

:export {
  mainColorPrimary: $main_color_primary;
  mainColorSecondary: $main_color_secondary;
  mainGradientPrimary: $main_gradient_primary;
  backgroundGradientPrimary: $background_gradient_primary;
  backgroundGradientSecondary: $background_gradient_secondary;
  backgroundDarkPrimary: $background_dark_primary;
  backgroundLightPrimary: $background_light_primary;
  textColor: $text_color;
  textPlaceholderColor: $text_placeholder_color;
  redColor: $redColor;
}

:root {
  --mainColorPrimary: #{$main_color_primary};
  --mainColorSecondary: #{$main_color_secondary};
  --mainGradientPrimary: #{$main_gradient_primary};
  --backgroundGradientPrimary: #{$background_gradient_primary};
  --backgroundGradientSecondary: #{$background_gradient_secondary};
  --backgroundDarkPrimary: #{$background_dark_primary};
  --backgroundLightPrimary: #{$background_light_primary};
  --textColor: #{$text_color};
  --textPlaceholderColor: #{$text_placeholder_color};
  --redColor: #{$redColor};
}
