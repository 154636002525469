body{
  .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .MuiBackdrop-invisible{
    background-color: rgba(0, 0, 0, 0);
  }
  .MuiSnackbar-anchorOriginTopRight{
    top: 40px;
    right: 40px;
    @media (max-width: 1199px) {
      top: 30px;
      right: 30px;
    }
    @media (max-width: 599px) {
      top: 20px;
      right: 20px;
    }
  }
  .MuiSnackbar-anchorOriginBottomRight{
    bottom: 40px;
    right: 40px;
    @media (max-width: 1199px) {
      bottom: 30px;
      right: 30px;
    }
    @media (max-width: 599px) {
      bottom: 20px;
      right: 20px;
    }
  }
  .MuiSnackbar-anchorOriginTopLeft{
    top: 40px;
    left: 40px;
    @media (max-width: 1199px) {
      top: 30px;
      left: 30px;
    }
    @media (max-width: 599px) {
      top: 20px;
      left: 20px;
    }
  }
  .MuiSnackbar-anchorOriginBottomLeft{
    bottom: 40px;
    left: 40px;
    @media (max-width: 1199px) {
      bottom: 30px;
      left: 30px;
    }
    @media (max-width: 599px) {
      bottom: 20px;
      left: 20px;
    }
  }
}
