@import "vars.module";
@import "reset";
@import "fonts";
@import "mixins";
@import "helpers";
@import "typography";
@import "mui.global";

body {
  background: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.16px;
  color: $text_color;

  @media (max-width: 1199px) {
    font-size: 15px;
  }
  @media (max-width: 599px) {
    font-size: 14px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

b, strong {
  font-weight: bold;
  color: $text_color;
}

em, i {
  font-style: italic;
}

a {
  outline: none;
  color: $main_color_primary;
  @include transition();

  &:hover{
    color: $main_color_secondary;
  }
}

.scrollbar-y{
  @include scrollbar();
}
