// Clearfix
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

// Reset list
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Transition
@mixin transition($name: all, $duration: 0.3, $animation: ease) {
  -webkit-transition: $name $duration+s $animation;
  -moz-transition: $name $duration+s $animation;
  -o-transition: $name $duration+s $animation;
  transition: $name $duration+s $animation;
}

// Custom scrollbar
@mixin scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-track
  {
    background-color: $background_light_primary;
    border-radius: 5px;
  }
  &::-webkit-scrollbar
  {
    width: 5px;
    background-color: $background_light_primary;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb
  {
    background: $main_color_primary;
    cursor: pointer;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $main_color_secondary;
  }
}
