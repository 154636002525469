
p {
  margin-bottom: 0px;

  &:last-child {
    margin-bottom: 0;
  }
}

ul, ol{
  margin: 0 0 20px 20px;

  li{
    margin-bottom: 10px;

    &:last-child{
      margin-bottom: 0;
    }
  }
}
